/*eslint-disable-next-line*/

html {}

body {
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(59, 113, 202, 1) 100%, rgba(59, 113, 202, 1) 100%, rgba(59, 113, 202, 1) 100%);
  background-attachment: fixed;
  /*edit*/
  margin: 0;
  height: 100vh;

}

::-webkit-scrollbar {
  display: none;
}

.FlashCardsListContainer {
  margin: 1rem 1rem;
  padding: .5rem;

}

.AutomaticHeaderContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.AutomaticHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 .6rem;
  padding-bottom: .6rem;
  margin-bottom: 1rem;
  border-radius: .5rem;
  width: 97%;
}


.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: .5rem;
  font-family: var(--Fonte);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: rgb(237, 235, 235);
  padding-left: .7rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 2rem;
  flex-grow: 1;
}

#form-groupBtn {
  padding: 0;

}




.form-group>label {
  color: #777;
  font-size: .75rem;
  margin-right: .3rem;
  font-family: var(--Fonte);
}

.form-group>select {
  padding: 3px 3px;
  border-radius: .3rem;
  outline: none;
  border: none;
  font-family: var(--Fonte);
  margin: 0;
  background-color: transparent;
  font-size: .75rem;
  cursor: pointer;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  flex-grow: 1;
}



.form-group>input {
  padding: 3px 3px;
  border-radius: .3rem;
  outline: none;
  border: none;
  font-family: var(--Fonte);
  margin: 0;
  background-color: transparent;
  font-size: .75rem;
  cursor: pointer;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  flex-grow: 1;

}

/* Chrome, Safari, Edge, Opera */
.form-group>input.form-group>input::-webkit-outer-spin-button,
.form-group>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Btn {
  cursor: pointer;
  border: none;
  color: white;
  background-color: var(--yellow) !important;
  font-family: var(--Fonte) !important;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Btn:hover {
  background-color: var(--yellowBrither);
}

.Card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}



.BackButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BackButton>svg {
  color: white;
  font-size: 1.5rem;
}



@media (max-width: 650px) {

  .AutomaticHeaderContainer {
    padding: 0;
    margin: 0;
  }

  .BackButton {
    display: none;

  }

  .AutomaticHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    flex-shrink: 1;
    padding: 0;
    width: 92%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .form-group {
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }


}