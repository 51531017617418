.FlipCardContainer {
    display: flex;
    flex-direction: column;
}

.FlipCard {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    background-color: var(--yellow);
    transform-style: preserve-3d;
    transition: 150ms;
    cursor: pointer;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.FlipCard:hover {
    --translate-y: -2px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

.FlipCard.FlipCardFlip {
    --rotate-y: 180deg;
}

.FlipCard .FlipCardFront {
    left: 0;
}

.FlipCard .FlipCardFront,
.FlipCard .FlipCardBack {
    position: absolute;
    padding: .6rem;
    padding-bottom: 1rem;
    backface-visibility: hidden;
    color: white;
    font-family: var(--Fonte);
    font-size: .8rem;
}

.FlipCard .FlipCardBack {
    transform: rotateY(180deg);
}







.FlipCardQuestion {
    color: white;
    font-family: var(--Fonte);
    font-size: .8rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.FlipCardOptions {
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

.FlipCardOptions>p {
    color: white;
    font-family: var(--Fonte);
    font-size: .7rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.FlipCardOptionDot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    margin-right: .2rem;
}


.FlipCardButtonsBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: .5rem;
    gap: .5rem;
}

.FlipCardButtonsBottom>button {
    font-size: .8rem;
    color: white;
    font-family: var(--Fonte);
    background-color: var(--blue);
    border-radius: .3rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem .4rem;

}

.FlipCardButtonsBottom>button:hover {
    background-color: var(--blueBrither);
}



@media (max-width: 650px) {

    .FlipCardContainer:last-child {
        padding-bottom: 7rem;
    }

    .FlipCardButtonsBottom {
        justify-content: flex-start;
        margin-bottom: .4rem;
    }

    .FlipCardButtonsBottom>button {
        font-size: 1rem;
        color: white;
        font-family: var(--Fonte);
        background-color: transparent;
        border-radius: .3rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .3rem .2rem;

    }

    .FlipCardButtonsBottom>button:hover {
        background-color: var(--yellowBrither);
    }

}