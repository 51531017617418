.Header {
    height: 15vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem 1.5rem 0.2rem 0.8rem;
    gap: 2rem;
    margin: 0;
    width: auto;
}


.LogoHeader {
    height: 3rem;
    cursor: pointer;
}

.UserPhotoHeader {
    height: 2.5rem;
    cursor: pointer;
}

.LogoHeaderCointainer {
    flex-grow: 1;
}

.PlayButtonHeader {
    padding: 8px 27px;
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--yellow);
    border-radius: 0.6rem;
    font-family: var(--Fonte);
}

.PlayButtonHeader:hover {
    background-color: var(--yellowBrither);
}


.HeaderNoShow {
    display: none;
}


.UserHeaderPhotoAndPlayButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.AutomaticPlayButton {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 3rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
    font-size: 1rem;
    margin-right: 1rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
}

.AutomaticPlayButton:hover {
    background-color: var(--yellowBrither);
}

.AutomaticPlayButton>svg {
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
    font-size: .7rem;
}


@media (max-width: 650px) {
    .UserPhotoHeader {
        display: none;
    }
}

@media (max-width: 900px) {



    .LogoHeader {
        height: 2rem;
    }


    .Header {
        justify-content: space-around;
        gap: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 5rem;
    }


    .AutomaticPlayButton {
        font-size: .8rem;
    }

    .AutomaticPlayButton>svg {
        font-size: .6rem;
    }


}