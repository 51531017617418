.Card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: var(--yellow);
  transform-style: preserve-3d;
  transition: 150ms;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.Card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

.Card.Flip {
  --rotate-y: 180deg;
}

.Card .Front {
  left: 0;
}

.Card .Front,
.Card .Back {
  position: absolute;
  padding: .6rem !important;
  padding-bottom: 1rem !important;
  backface-visibility: hidden;
  color: white;
  font-family: var(--Fonte);
  font-size: .8rem;
}

.Card .Back {
  transform: rotateY(180deg);
}

.flashcard-options {
  margin-top: .5rem;
}

.flashcard-option {
  margin-top: .1rem;
  color: rgb(235, 235, 235);
  font-size: .75rem;
  font-family: var(--Fonte);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flashcard-option:first-child {
  margin-top: 0;
}


.OptionDot {
  width: 4px;
  height: 4px;
  background-color: whitesmoke;
  display: inline-block;
  border-radius: 50%;
  margin-right: 3px;

}