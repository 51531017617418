@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mitr:wght@200;700&display=swap');

:root {
  --yellow: #E4A11B;
  --yellowBrither: #f2bb4e;
  --blue: #3B71CA;
  --blueBrither: #5f88cb;
  --Fonte: 'Kanit', 'Sans Serif';
}



.OBJ {
  padding: 2rem;
  background-color: aliceblue;
  margin: 1rem;
}

.OBJ2 {
  background-color: #E4A11B;
  margin: .3rem;
}