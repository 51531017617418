.EditCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.EditCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 2.5rem;
}

.EditCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
}

.EditCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}



.EditModalCardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.EditModalCardTitle {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.EditModalCardRadios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}


.EditModalCardRadio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}


.EditModalCardRadio>label {
    color: rgb(73, 72, 72);
    font-family: var(--Fonte);
    font-size: .9rem;
}

.EditModalCardRadio>input {
    margin-right: .2rem;
}

.EditModalCardTextChoiceContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.EditModalCardTextChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}





.EditModalCardMultipleChoiceContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}


.EditModalCardMultipleChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}


.EditModalCardOptionsTitle {
    margin: 1rem 0 0rem 0rem;
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.EditModalCardOption {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 70% 30%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: .4rem;
    border-radius: .5rem;
    padding: .5rem .7rem;
    background-color: #F7F7F7;

}

.EditModalCardOptionDot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    margin-right: .3rem;
    display: none;
}




.EditModalCardOptionName>p {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: rgb(73, 72, 72);
    font-weight: 500;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.EditModalCardOptionCorret {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    gap: .2rem;
}


.EditModalCardOptionCorret>label {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 500;
    color: rgb(114, 113, 113);
}

.EditModalCardOptionCorret>input {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 400;
}

.EditModalCardCorrectOption {
    color: #08672e !important;
}


.EditModalCardOption>button {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.EditModalCardOption>button>svg {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: var(--blue);
}



.EditModalCardAddOptionForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    gap: .5rem;
}

.EditModalCardAddOptionForm>input {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
    flex-grow: 1;
}

.EditModalCardAddOptionForm>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
}


.EditModalCardErrorMessage {
    width: 100%;
    color: #B71C1C;
    font-family: var(--Fonte);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: .5rem;
}


.EditModalCardButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.EditModalCardButtons>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
    margin-top: 1rem;
}

@media (max-width: 650px) {
    .EditCategoryModalTitle {
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .EditCategoryButtons>button {
        font-size: .9rem;
    }

    .EditModalCardBodyBootstrap {
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .EditModalCardBody {
        flex-grow: 1;
    }

}