@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mitr:wght@200;700&display=swap);
.Card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: var(--yellow);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  cursor: pointer;
  -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
          transform: perspective(1000px) rotateY(0) translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.Card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

.Card.Flip {
  --rotate-y: 180deg;
}

.Card .Front {
  left: 0;
}

.Card .Front,
.Card .Back {
  position: absolute;
  padding: .6rem !important;
  padding-bottom: 1rem !important;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  color: white;
  font-family: var(--Fonte);
  font-size: .8rem;
}

.Card .Back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flashcard-options {
  margin-top: .5rem;
}

.flashcard-option {
  margin-top: .1rem;
  color: rgb(235, 235, 235);
  font-size: .75rem;
  font-family: var(--Fonte);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flashcard-option:first-child {
  margin-top: 0;
}


.OptionDot {
  width: 4px;
  height: 4px;
  background-color: whitesmoke;
  display: inline-block;
  border-radius: 50%;
  margin-right: 3px;

}
.NoQuestionDescription>p {
    color: white;
    font-family: var(--Fonte);
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}

.NoQuestionDescription>p>svg {
    margin-right: .4rem;
}


@media (max-width: 900px) {
    .NoQuestionDescription>p {
        color: white;
        font-family: var(--Fonte);
        font-weight: 400;
        font-size: .7rem;
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }

 

}
/*eslint-disable-next-line*/

html {}

body {
  background: rgb(34, 193, 195);
  background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(59, 113, 202, 1) 100%, rgba(59, 113, 202, 1) 100%, rgba(59, 113, 202, 1) 100%);
  background-attachment: fixed;
  /*edit*/
  margin: 0;
  height: 100vh;

}

::-webkit-scrollbar {
  display: none;
}

.FlashCardsListContainer {
  margin: 1rem 1rem;
  padding: .5rem;

}

.AutomaticHeaderContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.AutomaticHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 .6rem;
  padding-bottom: .6rem;
  margin-bottom: 1rem;
  border-radius: .5rem;
  width: 97%;
}


.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: .5rem;
  font-family: var(--Fonte);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: rgb(237, 235, 235);
  padding-left: .7rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 2rem;
  flex-grow: 1;
}

#form-groupBtn {
  padding: 0;

}




.form-group>label {
  color: #777;
  font-size: .75rem;
  margin-right: .3rem;
  font-family: var(--Fonte);
}

.form-group>select {
  padding: 3px 3px;
  border-radius: .3rem;
  outline: none;
  border: none;
  font-family: var(--Fonte);
  margin: 0;
  background-color: transparent;
  font-size: .75rem;
  cursor: pointer;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  flex-grow: 1;
}



.form-group>input {
  padding: 3px 3px;
  border-radius: .3rem;
  outline: none;
  border: none;
  font-family: var(--Fonte);
  margin: 0;
  background-color: transparent;
  font-size: .75rem;
  cursor: pointer;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  flex-grow: 1;

}

/* Chrome, Safari, Edge, Opera */
.form-group>input.form-group>input::-webkit-outer-spin-button,
.form-group>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Btn {
  cursor: pointer;
  border: none;
  color: white;
  background-color: var(--yellow) !important;
  font-family: var(--Fonte) !important;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Btn:hover {
  background-color: var(--yellowBrither);
}

.Card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}



.BackButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BackButton>svg {
  color: white;
  font-size: 1.5rem;
}



@media (max-width: 650px) {

  .AutomaticHeaderContainer {
    padding: 0;
    margin: 0;
  }

  .BackButton {
    display: none;

  }

  .AutomaticHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    flex-shrink: 1;
    padding: 0;
    width: 92%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  .form-group {
    flex-grow: 0;
    margin-right: 0;
    margin-left: 0;
  }


}
.PlayingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    
}
.PlayingHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20% !important; 
    align-items: center;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-bottom: 2rem;
}


.PlayingHeaderProgressBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PlayingHeaderNextButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PlayingHeaderExitButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.PlayingHeaderExitButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.5rem;
    cursor: pointer;
}

.PlayingHeaderNextButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 2rem !important;
    cursor: pointer;
}


.PlayingHeaderProgressBar{   
     height: 2rem;
   
    width: 100%;
    background-color: aqua;
}







@media (max-width: 650px) {

    .PlayingHeader {
        width: 100%;
        display: grid;
        grid-template-columns: 20% 60% 20% !important; 
        align-items: center;
        justify-content: center;
       padding: 0;
        margin-bottom: 2rem;
    }
     
  
  
  }
.PlayingFlipCardContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.PlayingFlipCard {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
  background-color: var(--yellow);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  cursor: pointer;
  -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
          transform: perspective(1000px) rotateY(0) translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.PlayingFlipCard:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

.PlayingFlipCard.PlayingFlipCardFlip {
  --rotate-y: 180deg;
}

.PlayingFlipCard .PlayingFlipCardFront {
  left: 0;
}

.PlayingFlipCard .PlayingFlipCardFront,
.PlayingFlipCard .PlayingFlipCardBack {
  position: absolute;
  padding: 4rem 1.5rem;
  padding-bottom: 4rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  color: white;
  font-family: var(--Fonte);
  font-size: .8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.PlayingFlipCardQuestion {
  word-break: break-all;
}

.PlayingFlipCard .PlayingFlipCardBack {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.PlayingFlipCardOptions {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  margin-top: 3rem;
  grid-gap: .4rem;
  gap: .4rem;
  flex-grow: 1;
}

.PlayingFlipCardOption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem .5rem;
  background-color: white;
  width: 100%;
  border-radius: .25rem;
  cursor: pointer;
}


.IsAnswer {
  background-color: var(--yellow);
  color: white;
}

.PlayingFlipCardOption>p {
  margin: 0;
  font-family: var(--Fonte);
  font-size: .9rem;
  color: rgb(75, 74, 74);
}

.IsAnswer>p {
  color: white;
}



.PlayingFlipCardOption>svg {
  margin-right: .5rem;
  color: var(--yellow);

}

.IsAnswer>svg {
  color: white;
}

.PlayingFlipCardButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem .5rem;
  width: 100%;
}

.ICON {
  position: absolute;
  left: -2rem;
  bottom: -2rem;

}

.REVERSE {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.PlayingFlipCardButtons>button {
  cursor: pointer;
  border: none;
  background-color: var(--yellow);
  border-radius: 2rem;
  padding: .5rem 1.3rem;
  text-decoration: none;
  text-decoration: none;
  font-family: var(--Fonte);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: .4rem;
  gap: .4rem;
  outline: none;
  font-size: .9rem;
}

.PlayingFlipCardButtons>button:hover {
  background-color: var(--yellowBrither);
}

.PlayingFlipCardButtons>button:disabled {
  background-color: rgb(223, 223, 169) !important;
  cursor: not-allowed;
}

.PlayingFlipCardTextForm {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  margin-top: 3rem;
  grid-gap: .4rem;
  gap: .4rem;
  flex-grow: 1;

}


.PlayingFlipCardTextForm>input {
  border-radius: .5rem;
  padding: .5rem .7rem;
  border: none;
  background-color: #F7F7F7;
  font-family: var(--Fonte);
  font-size: .8rem;
  outline: none;
  color: rgb(73, 72, 72);
  width: 100%;
  margin-bottom: 1rem;

}

.PlayingFlipCardTextForm>textarea {
  border-radius: .5rem;
  padding: .5rem .7rem;
  border: none;
  background-color: #F7F7F7;
  font-family: var(--Fonte);
  font-size: .8rem;
  outline: none;
  color: rgb(73, 72, 72);
  width: 100%;
  margin-bottom: 1rem;
  min-height: 5rem;
}




.RoundIcon {
  margin-top: 1rem;
  border-radius: 50%;
  padding: .7rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, .3);
}


.NextIcon>button {
  margin-top: 2rem;
  cursor: pointer;
  border: none;
  background-color: var(--yellow);
  border-radius: 2rem;
  padding: .5rem 1.3rem;
  text-decoration: none;
  text-decoration: none;
  font-family: var(--Fonte);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: .4rem;
  gap: .4rem;
  outline: none;
  font-size: .9rem;
}



.NextIcon>button>svg {
  font-size: 1.1rem;
  color: white;
  font-family: var(--Fonte);
}

.RoundIcon>svg {
  font-size: 3rem;
  color: white;
}

.IconCorrect {
  background-color: #14A44D;
}

.IconWrong {
  background-color: #DC4C64;
}

.RoundIconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RoundMessage {
  font-family: var(--Fonte);
  font-size: .8rem;
  color: white;
  margin-top: .5rem;
}





@media (max-width: 850px) {

  .PlayingFlipCardContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

}


.NextCardButton {
  display: absolute !important;
  bottom: 1rem !important;
  right: 1rem;
}


@media (max-width: 650px) {

  .PlayingFlipCardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .PlayingFlipCardButtons {
    position: absolute !important;
    bottom: 1rem !important;
    width: 90%;
  }

  .ICON {
    position: absolute;
    left: -.7rem;
    bottom: -2rem;
  }

  .NextIcon {
    position: absolute !important;
    bottom: 1rem !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .NextIcon>button {
    width: 70%;
  }

}
.PlayingHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    justify-content: center;
}


.PlayingHeaderProgressBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PlayingHeaderNextButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PlayingHeaderExitButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.PlayingHeaderExitButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.5rem;
    cursor: pointer;
}

.PlayingHeaderNextButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.5rem;
    cursor: pointer;
}


.PlayingHeaderProgressBar {
    flex-grow: 1;
    height: 2rem;

    width: 100%;
    background-color: aqua;
}

.PlayingBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.FinishButton {

    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 3rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    font-size: 1rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
    margin-top: 2rem;
}



.RoundComplete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    color: white;
}
.SongSwitchButton {
    border: none;
    background-color: transparent;
    outline: none;
}

.SongSwitchButton>svg {
    color: black;
    font-size: 1.2rem;
}

.SongContainer {
    position: absolute;
    bottom: .5rem;
    left: .5rem;
}



@-webkit-keyframes btn-pisca {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}



@keyframes btn-pisca {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}
.SongSwitchButton { 
  -webkit-animation: btn-pisca .9s linear infinite;
  animation: btn-pisca .9s linear infinite;
}



@media (max-width: 650px) {
    
}
.FlashCardsContainer {
    padding: 1rem 2rem;
}



.Category {
    background-color: white;
    
    padding-right: .2rem;    
    border-radius: 0;
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
    font-family: var(--Fonte);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: .6rem;
    gap: .6rem;
    flex-grow: 1;
}


.CategoryContainer {
    background-color: var(--yellow);
    padding: 0;
    border-radius: .4rem;
    font-family: var(--Fonte);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: .6rem;

}

.CategoryLink{
    font-family: var(--Fonte);
    text-decoration: none;
}


.CategoryIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: .5rem;
    gap: .5rem;
    margin-right: 1rem;

}

.CategoryIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.CateoryNumber {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--yellow);
    width: 3rem;
    height: 100% !important;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
}


.CategoryIcons>svg {
    color: rgb(185, 183, 183);
    font-size: 1.2rem;

}



.CategoryName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    color: rgb(56, 56, 56);
    width: 30rem;
    padding: 1.6rem;
}

.CategoryName>p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.ButtonsCategories {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 2rem;
}


.ButtonsCategories>button {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .7rem 1.7rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    outline: none;
}

.ButtonsCategories>button:hover {
    background-color: var(--yellowBrither);
}









/*  MODAL ADIÇÃO DE CATEGORIA  */

/* blurred background modal for bootstrap v4 */
.modal-open> :not(.modal) {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
}

.AddCategoryModalBody {
    padding: 1rem;
}

.AddCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.3rem;
    color: white;
    font-weight: 600;
}

.AddCategoryFormGroup {
    margin-bottom: 1rem;
}



.AddCategoryFormGroup>input::-webkit-input-placeholder {

    color: rgb(84, 83, 83);
}



.AddCategoryFormGroup>input::placeholder {

    color: rgb(84, 83, 83);
}



.AddCategoryFormGroup>input {
    border-radius: .5rem;
    border: none;
    padding: 12px 20px;
    width: 100%;
    font-family: var(--Fonte);
    outline: none;

    color: rgb(84, 83, 83);
}

.AddCategoryFormGroup>select {
    border-radius: .5rem;
    border: none;
    padding: 12px 20px;
    width: 100%;
    font-family: var(--Fonte);
    outline: none;
    color: rgb(84, 83, 83);
}

.modal-body {
    background-color: var(--yellow);
}

.modal-content {
    overflow: hidden;
}

.AddCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.AddCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.AddCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}


/*  MODAL ADIÇÃO DE CATEGORIA  */




.NoCategories {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.NoCategories>p {
    font-family: var(--Fonte);
    color: white;
    font-size: 1.5rem;
}



@media (max-width: 650px) {

    .CategoryIcons>svg {
        font-size: 1rem;
    }

    .NoCategories>p {
        font-family: var(--Fonte);
        color: white;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .AddCategoryModalBody {
        padding: .3rem;
        padding-bottom: 1rem !important;
        padding-top: 3rem !important;
    }

    .modal-body {
        padding: .5rem !important;
    }

    .Category {
        font-size: .8rem;
    }

    .CateoryNumber {

        width: 2rem;
        min-width: 2rem !important;
        font-size: .8rem;
        font-weight: 500;

    }


    .ButtonsCategories {
        position: fixed;
        bottom: .5rem;
        right: .7rem;
        justify-content: center;

    }

    .ButtonsCategories>button {
        font-size: .8rem;
    }

    .FlashCardsContainer {
        height: 100vh;
        padding: .6rem .7rem;
    }

    .CategoryName {

        width: 10rem;
    }


}
.SelectedCategoryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: .4rem;
}

.SelectedCategoryCards {
    height: 100px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;

}

.SelectedCategoryHeader {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    flex-grow: 1;
    width: 100%;

}

.SelectedCategoryName {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.SelectedCategoryName>p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: var(--Fonte);
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
}

.SelectedCategoryBackButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.SelectedCategoryBackButton>svg {
    color: white;
    font-size: 1.8rem;
    font-weight: 500;

}

.SelectedCategoryIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 1rem;
    gap: 1rem;

}

.SelectedCategoryIcons>svg {
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
}


.SelectedCategoryButtons {
    margin-right: 0;
}


.SelectedCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 1.8rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    font-size: .7rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
}

.SelectedCategoryButtons>button>svg {
    font-size: 1.3rem;
}

.SelectedCategoryButtons>button:hover {
    background-color: var(--yellowBrither);
}


.AddCardButton {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 1.8rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    font-size: .7rem;
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
}

.AddCardButton>p {
    margin: 0;
    font-size: 1rem;
    font-family: var(--Fonte);
}

.AddCardButton>svg {
    font-size: 1rem;
    font-family: var(--Fonte);
}

@media (max-width: 1100px) {
    .SelectedCategoryHeader {
        display: grid;
        grid-template-columns: 10% 50% 40%;
        align-items: center;
        justify-content: flex-start;
        padding: .3rem;
    }
}

@media (max-width: 650px) {


    .SelectedCategoryName>p {
        font-size: 1rem;
        font-weight: 500;
    }

    .SelectedCategoryButtons {
        position: fixed;
        bottom: 5.5rem;
        right: 1rem;
        margin: 0;
        z-index: 3;
    }

    .AddCardButton {
        position: fixed;
        bottom: 1.5rem;
        right: 1rem;
        margin: 0;
        padding: 1rem 2rem;
        z-index: 3;
        background-color: var(--blue);
    }

    .AddCardButton>p {
        font-size: .7rem;

    }

    .SelectedCategoryButtons>button {
        background-color: var(--blue);
        padding: 1rem 2rem;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    }

    .SelectedCategoryButtons>button:hover {
        background-color: var(--blueBrither);
    }

    .SelectedCategoryButtons>button>svg {
        font-size: 1rem;
    }

    .SelectedCategoryIcons>svg {
        font-size: 1.2rem !important;
    }

    .SelectedCategoryBackButton>svg {
        font-size: 1.2rem;
    }

    .SelectedCategoryIcons {
        justify-content: flex-end;
    }

    .SelectedCategoryHeader {
        display: grid;
        grid-template-columns: 15% 60% 20%;
        align-items: center;
        justify-content: flex-start;
        padding: .3rem;
    }
}
.EditCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.EditCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.EditCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.EditCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}


@media (max-width: 650px) {
    .EditCategoryModalTitle {       
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .EditCategoryButtons>button{
        font-size: .9rem;
    }
}
.DeleteCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.DeleteCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.DeleteCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.DeleteCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}


@media (max-width: 650px) {
    .DeleteCategoryModalTitle {       
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .DeleteCategoryButtons>button{
        font-size: .9rem;
    }
}
.EditCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.EditCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.EditCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.EditCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}



.AddModalCardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.AddModalCardTitle {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.AddModalCardRadios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}


.AddModalCardRadio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}


.AddModalCardRadio>label {
    color: rgb(73, 72, 72);
    font-family: var(--Fonte);
    font-size: .9rem;
}

.AddModalCardRadio>input {
    margin-right: .2rem;
}

.AddModalCardTextChoiceContainer {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.AddModalCardTextChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}





.AddModalCardMultipleChoiceContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}


.AddModalCardMultipleChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}


.AddModalCardOptionsTitle {
    margin: 1rem 0 0rem 0rem;
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.AddModalCardOption {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 70% 30%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: .4rem;
    border-radius: .5rem;
    padding: .5rem .7rem;
    background-color: #F7F7F7;

}

.AddModalCardOptionDot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    margin-right: .3rem;
    display: none;
}




.AddModalCardOptionName>p {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: rgb(73, 72, 72);
    font-weight: 500;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.AddModalCardOptionCorret {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    grid-gap: .2rem;
    gap: .2rem;
}


.AddModalCardOptionCorret>label {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 500;
    color: rgb(114, 113, 113);
}

.AddModalCardOptionCorret>input {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 400;
}

.AddModalCardCorrectOption {
    color: #08672e !important;
}


.AddModalCardOption>button {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.AddModalCardOption>button>svg {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: var(--blue);
}



.AddModalCardAddOptionForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    grid-gap: .5rem;
    gap: .5rem;
}

.AddModalCardAddOptionForm>input {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
    flex-grow: 1;
}

.AddModalCardAddOptionForm>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
}


.AddModalCardErrorMessage {
    width: 100%;
    color: #B71C1C;
    font-family: var(--Fonte);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: .5rem;
}


.AddModalCardButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.AddModalCardButtons>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
    margin-top: 1rem;
}

@media (max-width: 650px) {
    .EditCategoryModalTitle {
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .EditCategoryButtons>button {
        font-size: .9rem;
    }

    .AddModalCardBodyBootstrap {
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .AddModalCardBody {
        flex-grow: 1;
    }

}
.FlipCardContainer {
    display: flex;
    flex-direction: column;
}

.FlipCard {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: .25rem;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
    background-color: var(--yellow);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: 150ms;
    cursor: pointer;
    -webkit-transform: perspective(1000px) rotateY(0) translateY(0);
            transform: perspective(1000px) rotateY(0) translateY(0);
    -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
            transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.FlipCard:hover {
    --translate-y: -2px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
}

.FlipCard.FlipCardFlip {
    --rotate-y: 180deg;
}

.FlipCard .FlipCardFront {
    left: 0;
}

.FlipCard .FlipCardFront,
.FlipCard .FlipCardBack {
    position: absolute;
    padding: .6rem;
    padding-bottom: 1rem;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    color: white;
    font-family: var(--Fonte);
    font-size: .8rem;
}

.FlipCard .FlipCardBack {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}







.FlipCardQuestion {
    color: white;
    font-family: var(--Fonte);
    font-size: .8rem;
    margin-bottom: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.FlipCardOptions {
    display: flex;
    flex-direction: column;
    grid-gap: .1rem;
    gap: .1rem;
}

.FlipCardOptions>p {
    color: white;
    font-family: var(--Fonte);
    font-size: .7rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.FlipCardOptionDot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    margin-right: .2rem;
}


.FlipCardButtonsBottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: .5rem;
    grid-gap: .5rem;
    gap: .5rem;
}

.FlipCardButtonsBottom>button {
    font-size: .8rem;
    color: white;
    font-family: var(--Fonte);
    background-color: var(--blue);
    border-radius: .3rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem .4rem;

}

.FlipCardButtonsBottom>button:hover {
    background-color: var(--blueBrither);
}



@media (max-width: 650px) {

    .FlipCardContainer:last-child {
        padding-bottom: 7rem;
    }

    .FlipCardButtonsBottom {
        justify-content: flex-start;
        margin-bottom: .4rem;
    }

    .FlipCardButtonsBottom>button {
        font-size: 1rem;
        color: white;
        font-family: var(--Fonte);
        background-color: transparent;
        border-radius: .3rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .3rem .2rem;

    }

    .FlipCardButtonsBottom>button:hover {
        background-color: var(--yellowBrither);
    }

}
.EditCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.EditCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.EditCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.EditCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}



.EditModalCardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.EditModalCardTitle {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.EditModalCardRadios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}


.EditModalCardRadio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}


.EditModalCardRadio>label {
    color: rgb(73, 72, 72);
    font-family: var(--Fonte);
    font-size: .9rem;
}

.EditModalCardRadio>input {
    margin-right: .2rem;
}

.EditModalCardTextChoiceContainer {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.EditModalCardTextChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}





.EditModalCardMultipleChoiceContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}


.EditModalCardMultipleChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}


.EditModalCardOptionsTitle {
    margin: 1rem 0 0rem 0rem;
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.EditModalCardOption {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 70% 30%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: .4rem;
    border-radius: .5rem;
    padding: .5rem .7rem;
    background-color: #F7F7F7;

}

.EditModalCardOptionDot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    margin-right: .3rem;
    display: none;
}




.EditModalCardOptionName>p {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: rgb(73, 72, 72);
    font-weight: 500;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.EditModalCardOptionCorret {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    grid-gap: .2rem;
    gap: .2rem;
}


.EditModalCardOptionCorret>label {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 500;
    color: rgb(114, 113, 113);
}

.EditModalCardOptionCorret>input {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 400;
}

.EditModalCardCorrectOption {
    color: #08672e !important;
}


.EditModalCardOption>button {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.EditModalCardOption>button>svg {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: var(--blue);
}



.EditModalCardAddOptionForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    grid-gap: .5rem;
    gap: .5rem;
}

.EditModalCardAddOptionForm>input {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
    flex-grow: 1;
}

.EditModalCardAddOptionForm>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
}


.EditModalCardErrorMessage {
    width: 100%;
    color: #B71C1C;
    font-family: var(--Fonte);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: .5rem;
}


.EditModalCardButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.EditModalCardButtons>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
    margin-top: 1rem;
}

@media (max-width: 650px) {
    .EditCategoryModalTitle {
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .EditCategoryButtons>button {
        font-size: .9rem;
    }

    .EditModalCardBodyBootstrap {
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .EditModalCardBody {
        flex-grow: 1;
    }

}
.DeleteCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.DeleteCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2.5rem;
}

.DeleteCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
}

.DeleteCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}


@media (max-width: 650px) {
    .DeleteCategoryModalTitle {       
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .DeleteCategoryButtons>button{
        font-size: .9rem;
    }
}
.PlayingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 2rem;
    gap: 2rem;
    
}
:root {
  --yellow: #E4A11B;
  --yellowBrither: #f2bb4e;
  --blue: #3B71CA;
  --blueBrither: #5f88cb;
  --Fonte: 'Kanit', 'Sans Serif';
}



.OBJ {
  padding: 2rem;
  background-color: aliceblue;
  margin: 1rem;
}

.OBJ2 {
  background-color: #E4A11B;
  margin: .3rem;
}
.Header {
    height: 15vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem 1.5rem 0.2rem 0.8rem;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0;
    width: auto;
}


.LogoHeader {
    height: 3rem;
    cursor: pointer;
}

.UserPhotoHeader {
    height: 2.5rem;
    cursor: pointer;
}

.LogoHeaderCointainer {
    flex-grow: 1;
}

.PlayButtonHeader {
    padding: 8px 27px;
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--yellow);
    border-radius: 0.6rem;
    font-family: var(--Fonte);
}

.PlayButtonHeader:hover {
    background-color: var(--yellowBrither);
}


.HeaderNoShow {
    display: none;
}


.UserHeaderPhotoAndPlayButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.AutomaticPlayButton {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 3rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    font-size: 1rem;
    margin-right: 1rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
}

.AutomaticPlayButton:hover {
    background-color: var(--yellowBrither);
}

.AutomaticPlayButton>svg {
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .4rem;
    gap: .4rem;
    font-size: .7rem;
}


@media (max-width: 650px) {
    .UserPhotoHeader {
        display: none;
    }
}

@media (max-width: 900px) {



    .LogoHeader {
        height: 2rem;
    }


    .Header {
        justify-content: space-around;
        grid-gap: 0;
        gap: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 5rem;
    }


    .AutomaticPlayButton {
        font-size: .8rem;
    }

    .AutomaticPlayButton>svg {
        font-size: .6rem;
    }


}
.Chosen {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: .2rem 1rem;
    margin-bottom: 1rem;
}


.NotChosen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    height: 70vh;
}












.LinkButton {
    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: 1rem 3rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
}

.LinkButton:hover {
    background-color: var(--yellowBrither);
}





@media (max-width: 650px) {

    .Buttons {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .NotChosen {
        flex-direction: column;
        height: 70vh;
    }

    .Chosen {
        padding: .1rem 1rem;
        margin-bottom: .3rem;
    }

    .LinkButton {
        width: 70%;
    }


}
.BottomBar {
    background-color: white;
    width: 100%;
    height: 7vh;
    position: fixed;
    bottom: 0 !important;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: .2rem;
    display: none;
    box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.75);
}

.BottomBarNoShow{
    display: none !important;
}

.BottomBar>a>svg {
    color: rgba(34, 193, 195, 1);
    font-size: 1.5rem;
}

.ActiveBottomBarItem {
    color: var(--blue) !important;
}



@media (max-width: 650px) {
    .BottomBar {
        display: flex;
    }
}
