.PlayingHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    justify-content: center;
}


.PlayingHeaderProgressBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PlayingHeaderNextButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PlayingHeaderExitButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.PlayingHeaderExitButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.5rem;
    cursor: pointer;
}

.PlayingHeaderNextButton>svg {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.5rem;
    cursor: pointer;
}


.PlayingHeaderProgressBar {
    flex-grow: 1;
    height: 2rem;

    width: 100%;
    background-color: aqua;
}

.PlayingBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.FinishButton {

    cursor: pointer;
    border: none;
    background-color: var(--yellow);
    border-radius: 2rem;
    padding: .4rem 3rem;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
    font-size: 1rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
    margin-top: 2rem;
}



.RoundComplete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    color: white;
}