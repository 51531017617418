.EditCategoryModalTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Fonte);
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}



.EditCategoryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 2.5rem;
}

.EditCategoryButtons>button {
    cursor: pointer;
    border: none;
    background-color: var(--blue);
    border-radius: 2rem;
    padding: .7rem 2rem;
    text-decoration: none;
    text-decoration: none;
    font-family: var(--Fonte);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .4rem;
}

.EditCategoryButtons>button:hover {
    background-color: var(--blueBrither);
}



.AddModalCardBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.AddModalCardTitle {
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.AddModalCardRadios {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}


.AddModalCardRadio {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
}


.AddModalCardRadio>label {
    color: rgb(73, 72, 72);
    font-family: var(--Fonte);
    font-size: .9rem;
}

.AddModalCardRadio>input {
    margin-right: .2rem;
}

.AddModalCardTextChoiceContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.AddModalCardTextChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}





.AddModalCardMultipleChoiceContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}


.AddModalCardMultipleChoiceContainer>textarea {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
}


.AddModalCardOptionsTitle {
    margin: 1rem 0 0rem 0rem;
    color: white;
    font-family: var(--Fonte);
    font-size: 1.1rem;
    font-weight: 600;
}

.AddModalCardOption {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 70% 30%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: .4rem;
    border-radius: .5rem;
    padding: .5rem .7rem;
    background-color: #F7F7F7;

}

.AddModalCardOptionDot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    margin-right: .3rem;
    display: none;
}




.AddModalCardOptionName>p {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: rgb(73, 72, 72);
    font-weight: 500;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.AddModalCardOptionCorret {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    gap: .2rem;
}


.AddModalCardOptionCorret>label {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 500;
    color: rgb(114, 113, 113);
}

.AddModalCardOptionCorret>input {
    font-family: var(--Fonte);
    font-size: .8rem;
    color: white;
    font-weight: 400;
}

.AddModalCardCorrectOption {
    color: #08672e !important;
}


.AddModalCardOption>button {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.AddModalCardOption>button>svg {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: var(--blue);
}



.AddModalCardAddOptionForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    gap: .5rem;
}

.AddModalCardAddOptionForm>input {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    background-color: #F7F7F7;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: rgb(73, 72, 72);
    flex-grow: 1;
}

.AddModalCardAddOptionForm>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
}


.AddModalCardErrorMessage {
    width: 100%;
    color: #B71C1C;
    font-family: var(--Fonte);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: .5rem;
}


.AddModalCardButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.AddModalCardButtons>button {
    border-radius: .5rem;
    padding: .5rem .7rem;
    border: none;
    font-family: var(--Fonte);
    font-size: .8rem;
    outline: none;
    color: white;
    background-color: var(--blue);
    margin-top: 1rem;
}

@media (max-width: 650px) {
    .EditCategoryModalTitle {
        font-size: 1rem;
        color: white;
        font-weight: 500;
    }

    .EditCategoryButtons>button {
        font-size: .9rem;
    }

    .AddModalCardBodyBootstrap {
        padding: 1rem !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .AddModalCardBody {
        flex-grow: 1;
    }

}