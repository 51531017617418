.NoQuestionDescription>p {
    color: white;
    font-family: var(--Fonte);
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}

.NoQuestionDescription>p>svg {
    margin-right: .4rem;
}


@media (max-width: 900px) {
    .NoQuestionDescription>p {
        color: white;
        font-family: var(--Fonte);
        font-weight: 400;
        font-size: .7rem;
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }

 

}